import DOMPurify from "isomorphic-dompurify";
import { browser } from "$app/environment";
import { getIdByKey, loadUser, unloadUser } from "$lib/utils";
import { getSubscriber } from "$lib/subscription-service";
import type { Intent } from "$lib/intents";
import type { LayoutLoad } from "./$types";

/**
 * Set page configuration options
 */
export const prerender = true;
export const trailingSlash = "always";

/**
 * Store receipt in localStorage for receipt linking
 */
if (browser) {
  const vpnReceiptBlob = sessionStorage.getItem("braveVpn.receipt");
  if (vpnReceiptBlob) {
    localStorage.setItem("braveVpn.receipt", vpnReceiptBlob);
  }

  const leoReceipt = sessionStorage.getItem("braveLeo.receipt");
  if (leoReceipt) {
    localStorage.setItem("braveLeo.receipt", leoReceipt);
  }

  const leoOrderId = sessionStorage.getItem("braveLeo.orderId");
  if (leoOrderId) {
    localStorage.setItem("braveLeo.orderId", leoOrderId);
  }
}

/** Set up default Trusted Type */
if (browser && window.trustedTypes) {
  // @ts-ignore
  trustedTypes.createPolicy("default", {
    createHTML: (dirty) => DOMPurify.sanitize(dirty, { RETURN_TRUSTED_TYPE: true }),
    createScriptURL: (urlStr) => {
      const url = new URL(urlStr);
      if (url.origin === "https://js.stripe.com") {
        return urlStr;
      }
    }
  });
}

/**
 * Load global data for pages
 */
export const load: LayoutLoad = async ({ url }) => {
  if (!browser) return;

  const orderId = url.searchParams.get("order_id");
  const intent = url.searchParams.get("intent") as Intent;
  const productKey = url.searchParams.get("product");
  const productId = url.searchParams.get("product_id") || (productKey && getIdByKey(productKey));
  const uxMode: "mobile" | undefined = url.searchParams.get("ux") || undefined;

  let user: User;
  try {
    user = await getSubscriber();
    loadUser(user);
  } catch (_) {
    unloadUser();
  }

  return {
    orderId,
    intent,
    productKey,
    productId,
    user,
    uxMode,
  };
};
